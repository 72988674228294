:root {
  font-size: 90%;
}

* {
  margin    : 0;
  padding   : 0;
  box-sizing: border-box;
}

body {
  margin: auto 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin       : 0;
  margin-top   : 0;
  margin-left  : 0;
  margin-right : 0;
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
}



/* Modal Styles */

.modal-first-backdrop {
  z-index: 400 !important;
}

.modal-first-dialog {
  z-index: 405 !important;
}


.modal-second-backdrop {
  z-index: 500 !important;
}

.modal-second-dialog {
  z-index: 501 !important;
}


.modal-loading-square {
  width    : 10vw !important;
  min-width: 10vw !important;
  min-width: 10vw !important;
}

.modal-70 {
  width    : 80vw;
  min-width: 80vw;
  min-width: 80vw;
}

.modal-85 {
  width    : 85vw;
  min-width: 85vw;
  min-width: 85vw;
}


.modal-95 {
  width    : 95vw;
  min-width: 95vw;
  min-width: 95vw;
}



/* Table Styles */

.text-wrap-normal {
  word-wrap: normal !important;
}